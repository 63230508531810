<template>
  <v-container style="max-width: 600px; height: 100%" class="pa-6 pt-10">
    <v-card class="show-code pb-5">
      <div class="mb-2"><strong>招待クーポンコード:</strong></div>
      <!-- <p class="body-2 text-left">
        あなたの招待コードで、友だちにすぐに使える5%OFFクーポン2枚がプレゼントされます。
        <br />
        <br />
        また、そのクーポンで友だちがお買い物をすると、あなたにも5%OFFクーポン2枚がプレゼントされます。
      </p> -->
      <div class="my-5 text-h2">
        <a @click="copyToClipboard">
          {{ my_code }}
        </a>
        <v-icon>mdi-content-copy</v-icon>
      </div>

      <template class="mb-3">
        <v-row justify="center" class="mt-3">
          <v-dialog v-model="dialog" persistent max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">もらった招待コードを追加</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">友達招待クーポンを追加</span>
              </v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="couponCode"
                  label="紹介者のクーポンコードをご入力"
                  required
                ></v-text-field>
                <small class="red--text">{{ this.message }}</small>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="handleCloseDialog()">キャンセル</v-btn>
                <v-btn color="blue darken-1" text @click="handleAddCoupon()">追加</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </v-card>
    <div class="mt-5">
      <strong>友達招待で割引クーポン2枚をGETしよう!</strong>
      <br />
      <strong>①招待コードを送る</strong>
      <br />
      ・まだレコセレを利用されていないお友だちに招待コードをシェアしていただきます。
      <br />
      ・友だちが招待クーポンを追加すると、すぐに使える5% OFFクーポン2枚がプレゼントされます。
      <br />
      ※招待クーポンの利用期限は2週間です。
      <br />
      <strong>②割引クーポン2枚をGET</strong>
      <br />
      ・友だちがそのクーポンを利用して、レコセレで初めての注文を完了すると、あなたにも5%
      OFFクーポン2枚がプレゼントされます。
      <br />
      ※招待人数に制限はございません。招待人数×2枚分のクーポンを最大GETできます。
    </div>
    <v-dialog v-model="dialogMessage">
      <div class="message">
        <div class="content">クーポンリストに追加しました</div>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
  import Methods from '@/api/method.js';

  export default {
    name: 'CouponList',

    data() {
      return {
        dialogMessage: false,
        dialog: false,
        couponCode: null,
        my_code: '',
        message: '',
      };
    },

    mounted() {
      this.fetchUser();
    },

    watch: {
      dialogMessage(val) {
        if (!val) return;
        setTimeout(() => (this.dialogMessage = false), 1000);
      },
    },

    methods: {
      async fetchUser() {
        try {
          const { data } = await Methods.GetUser({
            id: this.$store.state.user.user_id,
            // id: '63069d2d088d3542647c506e',
          });
          this.my_code = data?.introducer_code;
        } catch (error) {
          console.log(error);
        }
      },
      handleCloseDialog() {
        this.dialog = false;
        this.couponCode = null;
      },
      async copyToClipboard() {
        if (navigator.clipboard == undefined) {
          window.clipboardData.setData('Text', this.my_code);
        } else {
          try {
            await navigator.clipboard.writeText(this.my_code);
            alert('コピーできました！ : ' + this.my_code);
          } catch (error) {
            alert('コピーが失敗しました！');
          }
        }
        // コピーをお知らせする
      },
      async handleAddCoupon() {
        try {
          if (!this.couponCode) {
            throw {
              message: '*クーポンコードを入手してください。',
            };
          }
          const { data } = await Methods.AddCouponToUser({
            user_id: this.$store.state.user.user_id,
            introducer_code: this.couponCode,
          });
          if (data !== 1) throw data;
          this.dialogMessage = true;
          this.handleCloseDialog();
        } catch (error) {
          this.message = error.message;
        }
      },
    },
  };
</script>

<style>
  .show-code {
    padding: 12px;
    text-align: center;
  }

  .message {
    display: flex;
    justify-content: center;
    position: fixed;
    /* bottom: 15%; */
    left: 0;
    width: 100vw;
  }

  .message .content {
    padding: 4px;
    background-color: #272727;
    border-radius: 4px;
    font-size: 14px;
    color: white;
  }
</style>
